import FileSaver from "file-saver";

export function downloadJson(filename: string, payload: any) {
  const blob = new Blob([JSON.stringify(payload)], {
    type: "application/json;charset=utf-8",
  });
  FileSaver.saveAs(blob, filename);
}

export function downloadBlob(filename: string, blob: Blob) {
  FileSaver.saveAs(blob, filename);
}
