import { debugFactory } from "./debug";

export type RecordingState =
  | "checking-support"
  | "failed-unsupported"
  | "failed-not-allowed"
  | "requesting-user-media"
  | "starting"
  | "recording"
  | "stopping"
  | "stopped"
  | "failed";

const stateMessages: Record<RecordingState, string> = {
  "checking-support":
    "Checking whether your browser supports audio recording...",
  "failed-unsupported":
    "Failed to record – Your browser does not support audio recording. Please try with a recent version of Chrome or Firefox.",
  "failed-not-allowed":
    "Failed to record – Your browser did not allow to record audio. Please check your settings. Depending on your browser, there might be a notification near the address bar where you can allow recording.",
  "requesting-user-media": "Requesting user media...",
  starting: "Starting... ",
  recording: "Recording...",
  stopping: "Stopping...",
  stopped: "Stopped.",
  failed: "Failed to record",
};

type StateChangeCallback = (state: RecordingState, message: string) => void;
type AudioUrlCallback = (url: string) => void;
type BlobCallback = (blob: Blob, ext: string) => void;

type ConstructorParams = {
  onStateChange: StateChangeCallback;
  onAudioUrl?: AudioUrlCallback;
  onBlob?: BlobCallback;
};

const debug = debugFactory("recorder");

const buildSupportedMediaRecorder = (
  stream: MediaStream
): [MediaRecorder, string, string] => {
  const options = [
    { mimeType: "audio/mp4", ext: "mp4" },
    { mimeType: "audio/webm;codecs=opus", ext: "webm" },
    { mimeType: "audio/webm", ext: "webm" },
  ];

  debug("Looking for a supported media type...");

  for (const option of options) {
    debug(`- checking media type ${option.mimeType}...`);
    const { mimeType, ext } = option;
    if (!MediaRecorder.isTypeSupported(mimeType)) {
      debug(`  => mimeType ${mimeType} not supported`);
      continue;
    }
    debug(`  => mimeType ${mimeType} may be supported`);
    const mediaRecorder = new MediaRecorder(stream, {
      mimeType: mimeType,
    });
    debug(`Initialized MediaRecorder with mimeType ${mimeType} (ext: ${ext})`);

    return [mediaRecorder, mimeType, ext];
  }

  throw new Error(
    "This browser does not support recording in webm or mp4. Please try it with a recent version of Chrome or Firefox."
  );
};

export class Recorder {
  stream: MediaStream | null = null;
  mediaRecorder: MediaRecorder | null = null;

  onStateChange: StateChangeCallback;
  onAudioUrl?: AudioUrlCallback;
  onBlob?: BlobCallback;

  constructor({ onStateChange, onAudioUrl, onBlob }: ConstructorParams) {
    this.onStateChange = onStateChange;
    this.onAudioUrl = onAudioUrl;
    this.onBlob = onBlob;
  }

  changeState(newState: RecordingState, errorMessage?: string) {
    const message =
      stateMessages[newState] +
      (newState === "failed" && errorMessage
        ? ` – The following error occurred: ${errorMessage}`
        : "");
    this.onStateChange(newState, message);
    console.log(newState);
  }

  start() {
    this.changeState("checking-support");

    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      console.log("getUserMedia supported.");

      this.changeState("requesting-user-media");
      navigator.mediaDevices
        .getUserMedia(
          // constraints - only audio needed for this app
          {
            audio: true,
          }
        )

        // Success callback
        .then((stream) => {
          this.changeState("starting");

          this.stream = stream;

          const [mediaRecorder, mediaType, fileExt] =
            buildSupportedMediaRecorder(stream);

          console.log("mime type:", mediaRecorder.mimeType);

          this.mediaRecorder = mediaRecorder;

          console.log("start");
          mediaRecorder.start(1000);

          // type RecordingState = "inactive" | "paused" | "recording"
          // Here expected: "recording"
          if (mediaRecorder.state === "recording") {
            this.changeState(mediaRecorder.state);
          } else {
            console.error(
              `mediaRecorder is in unexpected state after starting to record: "${mediaRecorder.state}"`
            );
            throw `mediaRecorder is in unexpected state after starting to record: "${mediaRecorder.state}"`;
          }

          const chunks: Blob[] = [];

          mediaRecorder.ondataavailable = (e) => {
            console.log("ondataavailable", e);
            chunks.push(e.data);
          };

          mediaRecorder.onstop = (e) => {
            console.log("onstop", e);

            if (this.onBlob || this.onAudioUrl) {
              const blob = new Blob(chunks, { type: mediaType });
              if (this.onBlob) {
                this.onBlob(blob, fileExt);
              }
              if (this.onAudioUrl) {
                const audioURL = window.URL.createObjectURL(blob);
                this.onAudioUrl(audioURL);
              }
            }

            this.changeState("stopped");
          };
        })

        // Error callback
        .catch((err) => {
          console.log("The following getUserMedia error occurred: " + err);
          if (err.name === "NotAllowedError") {
            this.changeState("failed-not-allowed");
          } else {
            this.changeState("failed", err.message);
          }
        });
    } else {
      this.changeState("failed-unsupported");
      console.log("getUserMedia not supported on your browser!");
    }
  }

  stop() {
    this.changeState("stopping");

    if (this.mediaRecorder) {
      this.mediaRecorder.stop();
    } else {
      console.warn("recorder.stop() called, but this.mediaRecorder is blank");
    }

    if (this.stream) {
      // Stop streams so that the red dot stops appearing in the browser tab
      this.stream.getTracks().forEach((track) => track.stop());
    } else {
      console.warn("recorder.stop() called, but this.stream is blank");
    }
  }
}

// Notes:

// To download the audio file:

// const link = document.createElement('a');
// link.href = audioURL;
// link.download = "recording.webm";
// link.click();
