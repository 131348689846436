import { pad } from "./pad";

const xDecimal = (fn: (n: number) => number, n: number, d: number) => {
  const q = Math.pow(10, d);
  return fn(n * q) / q;
};

export const floorDecimal = xDecimal.bind(null, Math.floor);
export const roundDecimal = xDecimal.bind(null, Math.round);
export const ceilDecimal = xDecimal.bind(null, Math.ceil);

export const isNumber = (n: any): n is number =>
  typeof n === "number" && !isNaN(n) && isFinite(n);

export const doubleDigit = (n: number) => (n < 10 ? "0" : "") + Math.trunc(n);
export const tripleDigit = (n: number) => pad(Math.trunc(n), 3, "0");
