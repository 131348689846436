import debug from "debug";

export const debugFactory = debug;

export function consoleGroup(label: string, fn: () => void) {
  console.group(label);
  try {
    fn();
  } finally {
    console.groupEnd();
  }
}

export function consoleGroupCollapsed(label: string, fn: () => void) {
  console.groupCollapsed(label);
  try {
    fn();
  } finally {
    console.groupEnd();
  }
}
