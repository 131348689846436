export interface ProcessingStateInterface {
  start: (params: {
    title: string;
    message?: string;
    total?: number;
    onAbort?: () => void;
    onRetry?: () => void;
  }) => void;
  setMessage: (message: string) => void;
  setProgress: (progress: number, total: number) => void;
  fail: (title: string, message?: string) => void;
  finish: () => void;
  onAbort?: () => void;
}

export class UploadedResult {
  constructor(public filePath: string) {}
}

export class AbortedResult {}

type Result = UploadedResult | AbortedResult;

export function uploadBlobAsFormData(
  blob: Blob,
  url: string,
  fileExtension: string,
  fieldName = "file",
  processingState: ProcessingStateInterface
): Promise<Result> {
  const upload = async () => {
    // 1. Build parameters

    const formData = new FormData();

    // The fileExtension must come first, so that the backend has this already
    // in `req.body['fileExtension']` when the filename for the file needs
    // to be determined.
    formData.append("fileExtension", fileExtension);

    formData.append(fieldName, blob);

    // 2. Make request

    const response = await sendWithProgress(
      "POST",
      url,
      formData,
      processingState
    );

    // 3. Handle response

    if (!response.ok) {
      processingState.fail(
        `Upload failed – The server returned status ${response.status} ${response.statusText}`
      );
      console.error("Error uploading blob:", response);
    }

    processingState.finish();
    const payload = await response.json();

    if (typeof payload.filePath !== "string") {
      console.error(
        `Upload API response does not contain 'filePath' - payload: ${JSON.stringify(
          payload
        )}`
      );
    }

    return new UploadedResult(payload.filePath);
  };

  return new Promise((resolve, reject) => {
    const attempt = async () => {
      processingState.start({
        title: "Uploading...",
        onAbort: () => {
          resolve(new AbortedResult());
        },
        onRetry: () => {
          attempt().then(resolve, reject);
        },
      });

      return await upload();
    };

    attempt().then(resolve, reject);
  });
}

function sendWithProgress(
  method,
  url,
  formData,
  processingState: ProcessingStateInterface
): Promise<{
  ok: boolean;
  status: number;
  statusText: string;
  json: () => Promise<UploadedResult>;
}> {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    const originalOnAbort = processingState.onAbort;
    processingState.onAbort = () => {
      xhr.abort();
      originalOnAbort && originalOnAbort();
    };

    const progressHandler = (event: ProgressEvent) => {
      processingState.setProgress(event.loaded, event.total);
    };

    const completeHandler = () => {
      processingState.setMessage("Upload complete.");
      resolve({
        ok: true,
        status: xhr.status,
        statusText: xhr.statusText,
        json: async () => xhr.response,
      });
    };

    const errorHandler = (e) => {
      console.error("error", e);
      processingState.fail(
        "Upload failed",
        "There might be a problem with your internet connection."
      );

      // Don't fail, so that the user can retry
      // reject(new HandledError());
    };

    const abortHandler = () => {
      processingState.setMessage("Upload aborted.");
      reject(new HandledError());
    };

    xhr.responseType = "json";
    xhr.upload.addEventListener("progress", progressHandler, false);
    xhr.addEventListener("load", completeHandler, false);
    xhr.addEventListener("error", errorHandler, false);
    xhr.addEventListener("abort", abortHandler, false);
    xhr.open(method, url);
    xhr.send(formData);
  });
}

// The error has already been handled (an status message displayed).
class HandledError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = "HandledError";
  }
}
