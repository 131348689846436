import { getMessageFromError } from "@/lib/utils/error-handling-utils";
import { UserError } from "./UserError";
import { ErrorContext } from "./MyError";

function newErrorFromContext(context: ErrorContext, error: unknown): UserError {
  return new UserError({
    message: context.doing ? `error ${context.doing}` : `internal error`,
    // E.g. Error transcribing audio
    internal: {
      original: error,
      message: context.internal?.doing
        ? `error ${context.internal.doing}: ${getMessageFromError(error)}`
        : getMessageFromError(error),
    },
    context: [context],
  });
}
// message should be an -ing phrase, e.g. "transcribing audio", so that it can be used in `error while ${message}` or `failed ${message}`

export async function addContextToAnyError<T>(
  getContext: (() => ErrorContext) | ErrorContext,
  subjectFn: () => Promise<T>
): Promise<T> {
  try {
    return await subjectFn();
  } catch (error) {
    const context =
      typeof getContext === "function" ? getContext() : getContext;
    if (error instanceof UserError) {
      addContext(error, context);
    } else {
      throw newErrorFromContext(context, error);
    }

    throw error;
  }
}

export const doing = addContextToAnyError;

type Wrapper<T> = (fn: () => Promise<T>) => () => Promise<T>;

export const doingWrapper = <T>(
  c: (() => ErrorContext) | ErrorContext
): Wrapper<T> => {
  return (fn: () => Promise<T>) => () => doing<T>(c, fn);
};

export function addContextToAnyErrorSync<T>(
  getContext: () => ErrorContext,
  subjectFn: () => T
): T {
  try {
    return subjectFn();
  } catch (error) {
    const context = getContext();
    if (error instanceof UserError) {
      addContext(error, context);
    } else {
      throw newErrorFromContext(context, error);
    }

    throw error;
  }
}

export function addContext(error: UserError, context: ErrorContext): void {
  error.details.context ??= [];
  error.details.context.push(context);
}
