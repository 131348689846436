import { UserError } from "@/framework/errors/UserError";
import { MyErrorZ } from "@/framework/errors/MyError";
import { addContextToAnyError } from "@/framework/errors/error-context";
import { z } from "zod";

export async function fetchJSON(url: string, options?: RequestInit) {
  const method = options?.method?.toUpperCase() ?? "GET";
  return await addContextToAnyError(
    () => ({
      doing: "fetching data from the server",
      internal: {
        doing: `calling ${method} ${url}`,
        params: {},
      },
    }),
    async () => {
      const response = await fetch(url, options);
      if (!response.ok) {
        const json = await response.json();
        console.log(`error response:`, JSON.stringify(json, null, 2));

        const parseResult = z.object({ error: MyErrorZ }).safeParse(json);

        if (
          parseResult.success &&
          "error" in parseResult.data &&
          parseResult.data.error &&
          typeof parseResult.data.error === "object" &&
          "message" in parseResult.data.error
        ) {
          throw new UserError(parseResult.data.error);
        }

        throw new UserError({
          message: getGenericMessageFromHttpStatus(response.status),
          technicalMessage: `${method} ${url} returned ${response.status} ${response.statusText}`,
          httpStatus: response.status,
          params: {
            responseBody: JSON.stringify(json),
          },
        });
      }
      return await response.json();
    }
  );
}

function getGenericMessageFromHttpStatus(status: number) {
  switch (status) {
    case 401:
      return "you are not logged in";
    case 403:
      return "you don't have permission";
    case 404:
      return "not found";
    case 422:
      return "unprocessable";
    case 429:
      return "rate limit exceeded";
    default:
      return "internal error";
  }
}
