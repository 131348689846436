export function filterMap<T, U>(
  array: T[],
  callback: (item: T) => U | null | undefined
): U[] {
  return array.reduce((acc: U[], item) => {
    const result = callback(item);
    if (result) {
      acc.push(result);
    }
    return acc;
  }, [] as U[]);
}

export function withoutElementAtIndex<T>(array: T[], index: number): T[] {
  return array.filter((_, i) => i !== index);
}

export function withElementAtIndex<T>(
  array: T[],
  index: number,
  element: T
): T[] {
  return [...array.slice(0, index), element, ...array.slice(index)];
}

export function moveInArrayByIndex<T>(
  array: T[],
  fromIndex: number,
  toIndex: number
): T[] {
  if (fromIndex === toIndex) return array;
  if (fromIndex < 0) throw new Error("fromIndex < 0");
  if (fromIndex >= array.length) throw new Error("fromIndex >= array.length");
  if (toIndex < 0) throw new Error("toIndex < 0");
  if (toIndex >= array.length) throw new Error("toIndex >= array.length");

  const element = array[fromIndex];
  const tmpArray = withoutElementAtIndex(array, fromIndex);
  const resArray = withElementAtIndex(tmpArray, toIndex, element);
  return resArray;
}
