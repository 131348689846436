// reflect-metadata: fixes "Reflect.metadata is not a function" in WorkspaceManagerState.spec.ts
import "reflect-metadata";
import { action, makeAutoObservable, observable } from "mobx";
import { localStorageUtils } from "./localStorageUtils";

export class LocalStorageStringEntry {
  constructor(private readonly key: string) {}
  get value(): string | null {
    return localStorage.getItem(this.key);
  }
  set value(value: string | null) {
    value
      ? localStorage.setItem(this.key, value)
      : localStorage.removeItem(this.key);
  }
}

export class LocalStorageStringEntryWithFallback {
  constructor(
    private readonly key: string,
    private readonly fallbackKey: string
  ) {}
  get value(): string | null {
    const primaryValue = localStorage.getItem(this.key);
    if (primaryValue) return primaryValue;

    const fallbackValue = localStorage.getItem(this.fallbackKey);
    if (fallbackValue) {
      localStorage.setItem(this.key, fallbackValue);
      localStorage.removeItem(this.fallbackKey);
      return fallbackValue;
    }

    return null;
  }
  set value(value: string | null) {
    localStorage.removeItem(this.fallbackKey);
    value
      ? localStorage.setItem(this.key, value)
      : localStorage.removeItem(this.key);
  }
}

export class LocalStorageBooleanEntry {
  constructor(private readonly key: string) {}
  get value(): boolean | null {
    return localStorageUtils.getBoolean(this.key);
  }
  set value(value: boolean | null) {
    value === null
      ? localStorage.removeItem(this.key)
      : localStorageUtils.setBoolean(this.key, value);
  }
  on() {
    this.value = true;
  }
  off() {
    this.value = false;
  }
  enable() {
    this.value = true;
  }
  disable() {
    this.value = false;
  }
  get isEnabled() {
    return this.value;
  }
}

export class ObservableLocalStorageBooleanEntry {
  value: boolean | null;

  constructor(private readonly key: string) {
    this.value = localStorageUtils.getBoolean(this.key);
    makeAutoObservable(this, {
      value: observable,
      setValue: action,
    });
  }
  setValue(value: boolean) {
    this.value = value;
    value === null
      ? localStorage.removeItem(this.key)
      : localStorageUtils.setBoolean(this.key, value);
  }

  enable() {
    this.setValue(true);
  }
  disable() {
    this.setValue(false);
  }
  toggle() {
    this.setValue(!this.value);
  }
  get isEnabled() {
    return this.value;
  }
}

export class ObservableLocalStorageJson<T> {
  value: T | null;

  constructor(private readonly key: string) {
    this.value = localStorageUtils.getJSON(this.key) as T | null;
    makeAutoObservable(this, {
      value: observable,
      setValue: action,
    });
  }

  setValue(value: T | null) {
    this.value = value;
    value === null
      ? localStorage.removeItem(this.key)
      : localStorageUtils.setJSON(this.key, value);
  }

  patch(cb: (value: T | null) => T | null) {
    this.setValue(cb(this.value));
  }
}
